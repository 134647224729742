<template><div /></template>

<script setup lang="ts">
import { AppUpdate, AppUpdateAvailability, AppUpdateResultCode } from "@capawesome/capacitor-app-update";
import { Capacitor } from "@capacitor/core";
import { ServiceChecker } from "capacitor-plugin-gmshms-checker";

const { apm } = useApm();

onMounted(async () => {
    try {
        if (
            Capacitor.getPlatform() === "ios" ||
            (Capacitor.getPlatform() === "android" &&
                (!ServiceChecker || !(await ServiceChecker.isHMSAvailable()).value))
        ) {
            await checkAndPerformUpdate();
        }
    } catch (e) {
        if (Capacitor.getPlatform() === "ios" || Capacitor.getPlatform() === "android") {
            try {
                await checkAndPerformUpdate();
            } catch (e) {
                apm.captureError(e as Error);
            }
        }
    }
});

async function checkAndPerformUpdate() {
    const result = await AppUpdate.getAppUpdateInfo();
    if (result.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
        return;
    }
    if (Capacitor.getPlatform() === "android" && result.immediateUpdateAllowed) {
        await AppUpdate.performImmediateUpdate()
            .then((result) => {
                if (result.code !== AppUpdateResultCode.OK) {
                    apm.captureError(result.code.toString());
                }
            })
            .catch((e) => {
                apm.captureError(e);
            });
    } else if (Capacitor.getPlatform() === "ios") {
        await AppUpdate.openAppStore().catch((e) => {
            apm.captureError(e);
        });
    }
}
</script>
