<template><div /></template>

<script setup lang="ts">
import OneSignal from "onesignal-cordova-plugin";

const { apm } = useApm();
const { data: authData } = useAuth();

onMounted(async () => {
    await OneSignalInit();
});

async function OneSignalInit() {
    OneSignal.initialize("76501182-9782-43a6-90af-519a06faac4a");
    OneSignal.setConsentRequired(true);
    OneSignal.setConsentGiven(true);
    OneSignal.Location.setShared(false);
    if (authData.value?.id) {
        OneSignal.login(authData.value?.id.toString());
    }
    OneSignal.User.setLanguage(authData.value?.language || "cs");
    await checkAndRequestNotificationsPermissions();
}

async function checkAndRequestNotificationsPermissions() {
    try {
        const onesignalPermission = await OneSignal.Notifications.getPermissionAsync();
        if (onesignalPermission) {
            return;
        } else {
            OneSignal.Notifications.requestPermission(true);
        }
    } catch (e) {
        apm.captureError(String(e));
    }
}
</script>
