<template>
    <CommonModalComponent
        v-if="store.activeRide && authData && authData.driver && authData.driver.ciziTahac"
        id="passTheRideModal"
        ref="passTheRideModal"
        :title="$t('label.passTheRide.header')"
        :custom-footer="true"
    >
        <p>{{ $t("label.passTheRide.explanation") }}</p>
        <div id="formgroup" class="form-control-group">
            <label>{{ $t("auth.phone.label") }}</label>
            <vue-tel-input
                v-bind="optionsPhone"
                v-model="phone"
                class="border"
                valid-color="#28a745"
                @on-input="validatePhone"
            />
        </div>
        <template #footer>
            <button type="button" class="btn btn-secondary" @click="passTheRideModal?.closeModal()">
                {{ $t("btn.close") }}
            </button>
            <button type="button" class="btn btn-primary" @click="passTheRide">
                {{ $t("btn.continue") }}
            </button>
        </template>
    </CommonModalComponent>
</template>

<script setup lang="ts">
import { VueTelInput } from "vue-tel-input";
import type ModalComponent from "~/components/common/ModalComponent.vue";
import "vue-tel-input/vue-tel-input.css";

const { data: authData } = useAuth();

const passTheRideModal = ref<InstanceType<typeof ModalComponent>>();
const toast = useToast();
const axios = useAxios();
const { t } = useI18n();
const store = useStore();
const phone = ref("");
const optionsPhone = ref({
    dropdownOptions: {
        showSearchBox: true,
        showDialCodeInList: true,
        showDialCodeInSelection: true,
        showFlags: true,
        width: "390px"
    },

    inputOptions: { showDialCode: true, required: true },
    preferredCountries: ["CZ", "SK", "PL", "DE", "HU"],
    validCharactersOnly: true
});

interface IPhoneInputResponse {
    valid: boolean;
    number: string;
    nationalNumber: string;
    formatted: string;
    countryCode: string;
    countryCallingCode: string;
}

function validatePhone(_number: number, obj: IPhoneInputResponse) {
    if (obj.number) {
        phone.value = obj.number;
    }
}
async function passTheRide() {
    try {
        store.setLoading(true);
        await axios.post("api/ride/active/pass", {
            phone: phone.value
        });

        await store.loadActiveRide();

        toast.success(t("label.passTheRide.success").toString());

        store.setLoading(false);
        passTheRideModal.value?.closeModal();
    } catch (err) {
        toast.error(t("label.passTheRide.error").toString());

        store.setLoading(false);
    }
}
</script>
