<template>
    <div>
        <button
            id="dropdownCallButton"
            class="btn-icon me-3"
            type="button"
            style="color: limeGreen"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
        >
            <font-awesome-icon icon="phone-alt" />
        </button>

        <div class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownCallButton">
            <a class="dropdown-item disabled">{{ $t("terminal.phoneSupport") }}</a>
            <div class="dropdown-divider" />
            <div v-for="phone in phones" :key="phone.ST_FLAG">
                <a
                    v-if="
                        ((store.activeRide &&
                            ((store.activeRide.DEPO_PICKUP && store.activeRide.DEPO_PICKUP === phone.ST_FLAG) ||
                                ((!store.activeRide.DEPO_PICKUP || store.activeRide.DEPO_PICKUP === 'X') &&
                                    store.activeRide.DEPO_DROPOFF === phone.ST_FLAG))) ||
                            !store.activeRide ||
                            authStatus === 'unauthenticated') &&
                        phone.SUPPORT_PHONE?.length > 6 &&
                        phone.PLNY_NAZEV
                    "
                    :href="`tel:${phone.SUPPORT_PHONE ? phone.SUPPORT_PHONE.replace(/\s+/g, '') : ''}`"
                    class="dropdown-item"
                >
                    {{ phone.PLNY_NAZEV }}
                </a>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
const { status: authStatus } = useAuth();
const axios = useAxios();
interface Phone {
    SUPPORT_PHONE: string;
    PLNY_NAZEV?: string;
    ST_FLAG: string;
}

const phones = ref<Phone[]>([]);
const store = useStore();

onMounted(async () => {
    const { data } = await axios.get<Phone[]>("api/terminals/supportPhones");
    if (data !== null) {
        phones.value = data;
    }
});
</script>
